//main
$bodyColor: #f9fafb;
$bodyColor: #ffffff;

$primary: #0e7673;
$primary100: #ccfbda;
$primary200: #9af7c0;
$primary300: #65e9a7;
$primary400: #3ed398;

$secondary: #ffd023;
$secondary100: #fffae9;
$secondary200: #fff6d3;
$secondary300: #ffeca7;
$secondary400: #ffde65;

//alerts
$success: #0caf60;
$success100: #55c790;
$success200: #0caf60;

$warning: #ffd023;
$warning100: #ffde65;
$warning200: #e6bb20;

$error: #fd6a6a;
$error100: #fd8181;
$error200: #fd4f4f;

//additional color
$white: #ffffff;
$dark: #111827;
$purple: #8c62ff;
$blue: #0f62f9;
$orange: #fe964a;

//grey_scale
$greyD: #718096;
$grey100: #f5f6fb;
$grey200: #f1f2f4;
$grey300: #cbd5e0;
$grey500: #a0aec0;

//Unknown Colors
$un_gray: #f9fafb;

//container padding
//0.75rem
$cp: 16px;
$cp2: 12px;
$maxWdt: 768px;
$sideBarWidth: 70px;
$stickyTop: 50px;

// lab test
$ltPrimary: #0e7673;
$ltGreyScale100: #f9fafb;
$ltGreyScale300: #eeeff2;
$ltGreyScale500: #FAFAFA;
$ltGreyScale400: #cbd5e0;
$ltGreyScale600: #718096;
$ltGreyScale700: #323b49;
$ltTitle: #1f2937;
$ltError: #fd6a6a;
$ltOrange: #fe964a;
$ltOrangeFaded: rgba(254, 150, 74, 0.1);
$ltErrorFaded: #fff0f0;
$fadedPrimary: rgba(29, 160, 153, 0.1);

// New Changes
$blue: #0f62f9;

// primary update
$primary: #0e7673;
$primary100: #ccf8e7;
$primary200: #9cf1d8;
$primary300: #64d5be;
$primary400: #3aac9f;
