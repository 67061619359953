@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.tags_title {
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    margin-bottom: 10px;
}

.item_all {
    position: sticky;
    left: 0;
}

.item_wrap {
    @include mixin.itemsCenter;
    white-space: nowrap;
    @include mixin.boxShadow;

    span {
        margin-right: 5px;
        @include mixin.itemsCenter;
        svg {
            width: 8px;
            height: 8px;
            @include mixin.itemsCenter;
        }
    }
    .close {
        margin: 0;
        margin-left: 10px;
        svg {
            width: 15px;
            width: 15px;
        }
    }

    font-size: 10px;
    padding: 10px;
    border: 1px solid variables.$grey200;
    @include mixin.borderRadius;
    margin-right: 10px;
    @include mixin.lastChildMargin;
    @include mixin.itemsCenter;
}

.not_found {
    padding: 10px;
    height: auto;
    @include mixin.itemsCenter;
    justify-content: center;
    flex-direction: column;
    min-height: 60vh;

    img {
        max-width: 150px;
        padding: 50px 0 30px 0;
    }

    .content {
        @include mixin.itemsCenter;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            background-color: variables.$secondary200;
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            @include mixin.borderRadius;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 1.5px;
                //
                vertical-align: middle;
                background-color: variables.$dark;
            }
            &::before {
                margin-right: 10px;
            }
            &::after {
                margin-left: 10px;
            }
        }
        span {
            font-weight: 400;
            font-size: 14px;
            color: variables.$greyD;
        }
    }
}

.tag_not_found {
    min-height: 30vh;
}

.shopping_cart {
    @include mixin.borderRadius;
    border: 1px solid var(--greyscale-300, #eeeff2);
    // padding: 10px;
}
.wrap_card_product {
    background-color: variables.$white;
    @include mixin.borderRadius;
    @include mixin.boxShadow;

    .card_product {
        border-bottom: 1px solid variables.$grey200;
        padding: 10px;
        @include mixin.itemsCenter;
        // width: 100%;

        &:last-child {
            border: none;
        }
        .img_wrap {
            min-width: 75px;
            min-height: 75px;
            max-width: 75px;
            max-height: 75px;
            overflow: hidden;
            position: relative;
            // border-radius: 100%;
            @include mixin.borderRadius;
            border: 1px solid variables.$grey300;
            @include mixin.itemsCenter;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                aspect-ratio: 1;
            }

            .top_off {
                @include mixin.absolute(0, 0, 0, 10px);
                width: 22px;
                height: 17px;

                span {
                    position: relative;
                    border-radius: 0;
                    font-size: 7px;
                    font-weight: 900;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    // padding: 8px 5px 5px 5px;
                    padding-top: 3px;
                    color: variables.$white;
                    background-color: variables.$blue;
                    z-index: 1;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 17px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                        background-size: 100%;
                        background-repeat: no-repeat;
                        transform: rotate(0deg);
                    }
                }
            }
            .flash_off {
                @include mixin.absolute(0, 0, 0, 50%);
                transform: translateX(-50%);
                height: 12px;
                width: 50px;
                @include mixin.itemsCenter;

                span {
                    border-radius: 0 0 6px 6px;
                    font-size: 7px;
                    font-weight: 500;
                    width: 50px;
                    height: 12px;
                    position: relative;
                    background-color: transparent;
                    z-index: 1;
                    background-image: url("../../../services/utils//svg/flash_sale_bg_1.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    @include mixin.itemsCenter;
                    justify-content: center;
                    color: variables.$white;

                    svg {
                        width: 3.084px;
                        height: 6px;
                        margin-right: 3px;
                        height: auto;
                        @include mixin.itemsCenter;
                    }
                }
            }

            .out_off_stock_wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba($color: rgba(238, 239, 242, 1), $alpha: 0.8);
                display: flex;
                align-items: center;
                justify-content: center;
                .out_off_stock {
                    font-size: 10px;
                    color: variables.$error;
                    padding-left: 0;
                    background-color: variables.$dark;
                    color: variables.$white;
                    border-radius: 4px;
                    padding: 2px 5px;
                    display: inline-block;
                    text-transform: capitalize;
                    position: absolute;
                    opacity: 0.8;
                }
            }
        }

        .content {
            padding-left: 10px;
            width: 100%;
        }
        .details_wrap {
            @include mixin.flexBetween;

            // width: 100%;
            a {
                color: variables.$dark;
            }

            .details {
                max-width: 50%;
                @include mixin.flexColumn;
                justify-content: space-evenly;

                h3 {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                }
                span {
                    font-size: 10px;
                    color: variables.$greyD;
                }
                p {
                    font-size: 10px;
                    margin: 0;
                    @include mixin.itemsCenter;
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
            .actions {
                max-width: 50%;

                .icon {
                    @include mixin.itemsCenter;
                    justify-content: flex-end;

                    span {
                        @include mixin.itemsCenter;
                        margin-right: 3px;
                        @include mixin.lastChildMargin;

                        svg {
                            color: variables.$grey500;
                            width: 25px;
                            height: 25px;
                        }
                    }

                    .delete_icon {
                        width: 18px;
                        height: 18px;
                    }
                    .delivery_icon {
                        @include mixin.itemsCenter;
                        margin-right: 5px;
                    }
                }

                .price {
                    display: flex;
                    justify-content: flex-end;
                    margin: 1px 0;

                    del {
                        font-size: 10px;
                        color: variables.$greyD;
                    }
                    b {
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
                @media only screen and (max-width: 320px) {
                    .price {
                        @include mixin.flexColumn;
                        justify-content: flex-end;
                        margin-top: 5px;

                        del {
                            @include mixin.flexConEnd;
                        }
                        b {
                            @include mixin.flexConEnd;
                        }
                    }
                }

                .cart_tags {
                    @include mixin.flexBetween;
                    justify-content: flex-end;
                    align-items: center;

                    button {
                        margin-left: 10px;
                    }
                    .tags {
                        @include mixin.itemsCenter;
                        justify-content: center;
                        // padding: 3px;
                        color: variables.$grey500;
                        border: 1px solid variables.$grey500;
                        @include mixin.borderRadius;
                        svg {
                            @include mixin.itemsCenter;
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
                @media only screen and (max-width: 320px) {
                    .cart_tags {
                        width: 100%;
                        @include mixin.flexColumn;
                        align-items: flex-end;
                        button {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        .bottom {
            & > p {
                color: variables.$error;
                font-size: 10px;
                margin: 0;
            }
            .regular {
                svg {
                    color: variables.$primary;
                }
            }

            .details {
                display: flex;
            }
        }
    }
    .not_found {
        padding: 10px;
        height: 50vh;
        @include mixin.itemsCenter;
        justify-content: center;
    }
}

.modal_info {
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0;

    p {
        color: variables.$error;
    }
}

.wishlist_modal_title {
    @include mixin.flexBetween;
    width: 100%;

    .add {
        @include mixin.itemsCenter;
        font-weight: 500;
        font-size: 14px;
        color: variables.$primary;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
    .remove {
        @include mixin.itemsCenter;
        font-weight: 400;
        font-size: 14px;
        color: variables.$greyD;

        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
}

.wish_list_modal_info {
    .add_tags {
        margin-top: 10px;
        @include mixin.itemsCenter;
        @include mixin.borderRadius;
        border: 1px solid variables.$grey300;
        overflow: hidden;

        span {
            @include mixin.itemsCenter;
            color: yellow;
            svg {
                @include mixin.itemsCenter;
                width: 35px;
                height: 35px;
            }
        }
        input {
            width: 100%;
            padding: 10px 10px 10px 0;
            border: none;
            outline: none;

            &:focus {
                border: none;
                outline: none;
            }
        }
        button {
            display: flex;
            height: 100%;
            padding: 10px;
            background-color: variables.$primary;
            border: 1px solid none;
            border: none;
            font-size: 14px;
            font-weight: 600;
            color: variables.$white;
        }
    }
    p {
        font-size: 12px;
        font-weight: 500;
    }

    .tag_list_wrp {
        .tag_item {
            @include mixin.borderRadius;
            @include mixin.itemsCenter;
            justify-content: space-between;
            // background-color: rgba($color: variables.$primary, $alpha: 0.1);
            // border: 2px solid variables.$primary;
            padding: 10px;
            margin-bottom: 10px;
            .content {
                @include mixin.itemsCenter;
            }
            svg {
                width: 16px;
                height: 16px;
                @include mixin.itemsCenter;
                margin-right: 10px;
            }

            .checkbox {
                svg {
                    color: variables.$grey300;
                    width: 20px;
                    height: 20px;
                }
            }
            .active {
                svg {
                    color: variables.$primary;
                }
            }
            .dot {
                @include mixin.itemsCenter;
                // color: variables.$primary;
                font-size: 12px;
                font-weight: 400;

                svg {
                    width: 8px;
                    height: 8px;
                }
            }
            .close {
                svg {
                    margin: 0;
                }
            }
        }
    }
}
