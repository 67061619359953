@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.img_wrap {
    position: relative;
    width: 100%;
    @include mixin.flexCenter;
    @include mixin.borderRadius;
    overflow: hidden;

    img {
        position: relative !important;
        object-fit: contain;
        width: 100%;
        height: auto;
        @include mixin.borderRadius;
    }
}
