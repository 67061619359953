@use "./variables.scss";

//flex box
@mixin flex {
    display: flex;
}

@mixin flexCenter {
    @include flex;
    justify-content: center;
    align-items: center;
}

@mixin itemsCenter {
    @include flex;
    align-items: center;
}
@mixin flexColumn {
    @include flex;
    flex-direction: column;
}
@mixin flexConEnd {
    @include flex;
    justify-content: flex-end;
}

@mixin flexColCenter {
    @include flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@mixin flexBetween {
    @include flex;
    justify-content: space-between;
}
@mixin flexColBetween {
    @include flex;
    flex-direction: column;
    justify-content: space-between;
}
@mixin flexCenterBetween {
    @include flex;
    align-items: center;
    justify-content: space-between;
}

// utils
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}
@mixin transition {
    transition: all 0.6s ease-in-out;
}

@mixin transition2 {
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
@mixin transition3 {
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin transition5 {
    transition: all 5s ease-in-out;
}

@mixin border {
    border: 1px solid variables.$grey200;
}

@mixin borderBtm {
    border-bottom: 1px solid variables.$grey200;
}

@mixin ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin lastChildMargin {
    &:last-child {
        margin-right: 0;
    }
}
//border
@mixin borderRadius($topLeft: 6px, $topRight: 6px, $bottomRight: 6px, $bottomLeft: 6px) {
    border-top-left-radius: $topLeft;
    border-top-right-radius: $topRight;
    border-bottom-right-radius: $bottomRight;
    border-bottom-left-radius: $bottomLeft;
}

@mixin boxShadow {
    box-shadow: 0 2px 5px variables.$grey200;
}
@mixin boxShadow2 {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}
@mixin boxShadowOrder {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

//button
@mixin buttonOutline {
    padding: 8px;
    margin: 10px 0;
    border: 1px solid variables.$primary;
    color: variables.$primary;
    width: 100%;
}

//scroll bar
// &::-webkit-scrollbar {
//     background-color: rgba($color: variables.$primary, $alpha: 0.1);
//     width: 5px;
// }
// &::-webkit-scrollbar-thumb {
//     background-color: rgba($color: variables.$primary, $alpha: 1);
//     width: 5px;
//     border-radius: 4px;
// }
// scrollbar-width: 5px;
// scrollbar-color: rgba($color: variables.$primary, $alpha: 0.1);

@mixin scrollBarTransparent {
    &::-webkit-scrollbar {
        width: 0;
    }
    &::-webkit-scrollbar-thumb {
        width: 0;
    }
    scrollbar-width: 0;
}

@mixin ltBodyTypo($fontSize: 12px, $lineHeight: 19.2px) {
    font-size: $fontSize;
    line-height: $lineHeight;
}

@mixin ltBoxShadow() {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.04);
}
